@import '../../variable.css';


.bg_color{
   background-color: var(--title_black);
   vertical-align: baseline;
}
.bg_grey{
    background-color: var(--grey);
}
.head_bg_yellow{
    background-color: var(--header-yellow);
}
.val_bg_yellow{
    background-color: var(--value-yellow);
}
.val_color_yellow{
    color: var(--black) !important;
}
.head_bg_blue{
    background-color: var(--header-blue) !important;
}
.val_bg_blue{
    background-color: var(--value-blue);
}
.p_bar_rad{
    border-radius: 0px !important;
}
.no_border_bottom{
    border-bottom: none !important;
}
.h_bottom{
    border-bottom: 1px solid var(--dark-grey);
}
.w-5{
    width: 5%;
    padding: 14px 0px !important;
    flex-flow: column-reverse;
}
.w-25{
    width: 25%;
}
.w-35{
    width: 35%;
}
.bar_text{    
    font-weight: 600;
    text-align: justify !important;
}
.wrap{
    text-overflow: ellipsis;
}
tr{
    height: 40px !important;
}
td{
    height: 40px !important;
    padding: 4px 16px !important;
}
/* li.Mui-selected, li.MuiMenuItem-root:hover {
    background-color: #4169E1 !important;
    color: #fff !important;
} */
li.MuiMenuItem-root{
    min-height: 30px !important;
    padding: 5px 36px 5px 25px !important;
}
div.select-input{
    width: 85%;
}
.ml_n25{
    margin-left: -25px;
}
.ml_n40{
    margin-left: -40px;
}
.lbl{
    padding: 10px 25px 0px 0px !important;
}
.textfield-label{
    margin: 0 !important;
}
.mt-20{
    margin-top: 20px !important;
}
.mt-28{
    margin-top: 28px !important;
}
#districtTable #stateTable tr{
    text-align: left;
}
#districtTable #stateTable .p_bar_rad{
    height: 100%;
}
.pass_lbl{
    padding: 0px 25px 5px 0px !important;
}
.pass_sub_text{
    padding: 0px 25px 25px 0px !important;
    font-size: 1rem !important;
}
.syl_link{
    text-decoration: none !important;
    color: var(--a-color) !important;
    cursor: pointer;
}
.pass_download{
    padding: 25px 12px 5px 0px !important;
}
.ds_syl_width{
    min-width: 340px;
    max-width: 340px;
}
@import '../../variable.css';

.grey_bg{
    background-color: var(--grey);
}
.card-bg{
    box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02) !important;
}
.lbl_text{
    font-weight: 700;    
    font-size: 1.5rem;
}
.demo_lbl{
    padding: 0px 25px 0px 0px;
}
.demo_sub_text{    
    padding: 0px 25px 25px 0px;    
 }
.demo_pdf{
    padding: 10px 12px 5px 0px !important;
}